import http from './http';

class UserApi {
  static userList(pageSize = 100000, pageNo = 1, nickname = undefined, phone = undefined, origin  = undefined) {
    return http.post('/zp-mobile/v1/backend/user/search-user-list', {
      pageSize,
      pageNo,
      nickname,
      phone,
      origin
    });
  }

  static updateUser(param) {
    return http.post('/zp-mobile/v1/backend/user/update-user', param);
  }

  static userCreditRecord(param) {
    return http.post('/zp-mobile/v1/backend/credit/detail', param);
  }

  // 用户添加积分
  static userIncreaseCreditRecord(param) {
    return http.post('/zp-mobile/v1/backend/credit/increase', param);
  }

  // 用户减少积分
  static userDecreaseCreditRecord(param) {
    return http.post('/zp-mobile/v1/backend/credit/increase', param);
  }
}

export default UserApi;
