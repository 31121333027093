<template>
  <div>
    <div style="margin-bottom: 20px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="手机号">
              <el-input v-model="form.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户昵称">
              <el-input v-model="form.nickname"></el-input>
            </el-form-item
            >
          </el-col>
          <el-col :span="8">
            <el-form-item label="用户来源">
              <el-select v-model="form.origin" placeholder="请选择" clearable style="width: 100%">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item style="text-align: left">
              <el-button type="primary" @click="onSearch">搜索</el-button>
              <el-button @click="onCancel">取消</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-table :data="userList" border>
      <el-table-column prop="id" label="用户Id"></el-table-column>
      <el-table-column prop="isSuperManager" label="超级管理员">
        <template slot-scope="scope">
          {{ scope.row.isSuperManager ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column prop="nickname" label="用户昵称"></el-table-column>
      <el-table-column prop="phone" label="用户手机号"></el-table-column>
      <el-table-column prop="avatar" label="用户头像">
        <template slot-scope="scope">
          <el-avatar slot="reference" :src="scope.row.avatar"/>
        </template>
      </el-table-column>
      <el-table-column prop="userAuditStatusDes" label="用户审核状态"></el-table-column>
      <el-table-column prop="createTime" label="注册时间"></el-table-column>
      <el-table-column label="操作" align="center" width="220" fixed="right">
        <template slot-scope="scope">
          <el-popconfirm title="确认要通过吗?" @confirm="handleUpdateStatus(scope.row, 'NORMAL')">
            <el-button slot="reference" size="mini" type="text" style="margin-right: 10px"> 通过</el-button>
          </el-popconfirm>
          <el-popconfirm title="确认要拒绝吗?" @confirm="handleUpdateStatus(scope.row, 'REJECT')">
            <el-button slot="reference" size="mini" type="text" style="margin-right: 10px"> 拒绝</el-button>
          </el-popconfirm>
          <el-popconfirm title="确认要赠送吗?" @confirm="handleManagerCreditRecord(scope.row)">
            <el-button slot="reference" size="mini" type="text" style="margin-right: 10px"> 赠送积分</el-button>
          </el-popconfirm>
          <el-button slot="reference" @click="openDrawer(scope.row)" size="mini" type="text"> 积分详情</el-button>
          <el-popconfirm :title="scope.row.isSuperManager ? '确认取消超级管理员吗?' : '设为超级管理员吗?'"
                         @confirm="handleUpdateStatus(scope.row, 'ADMIN')">
            <el-button slot="reference" size="mini" type="text" style="margin-right: 10px">
              {{ scope.row.isSuperManager ? '取消' : '设为' }}超级管理员
            </el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background style="margin-top: 20px" :total="totalRows" :current-page="page" :page-size="pageSize"
                   layout="prev, pager, next" @current-change="handleChangePage"></el-pagination>
    <el-drawer title="积分详情" :visible.sync="drawer" :direction="direction" size="50%">
      <div style="margin: 20px">
        <el-table :data="creditRecord" border>
          <el-table-column prop="creditPoints" label="积分">
            <template slot-scope="scope"> {{ scope.row.directionType === 'IN' ? '+' : '-' }}{{
                scope.row.creditPoints
              }}
            </template>
          </el-table-column>
          <el-table-column prop="descriptions" label="来源"></el-table-column>
          <el-table-column prop="directionType" label="方向"></el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <el-dialog :title="title" :visible="dialogVisible" @close="dialogVisible = false" width="30%">
      <el-input v-model="creditPoints" placeholder="输入积分值"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmGive">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import UserApi from '@/request/UserApi.js';
import CreditRuleApi from '@/request/CreditRule.js';

export default {
  data() {
    return {
      page: 1,
      pageSize: 10,
      userList: [],
      totalRows: 20,
      drawer: false,
      direction: 'rtl',
      type: 'increase',
      creditRecord: [],
      title: '输入增加的积分值',
      dialogVisible: false,
      creditPoints: undefined,
      form: {
        phone: undefined,
        origin: undefined,
        nickname: undefined,
      },
      options: [{
        value: '小程序',
        label: '小程序'
      }, {
        value: '视频号',
        label: '视频号'
      }],
    };
  },
  mounted() {
    this.loadUserList();
  },
  methods: {
    async openDrawer(row) {
      this.drawer = true;
      await this.handleUserCreditRecord(row);
    },
    async loadUserList() {
      const result = await UserApi.userList(this.pageSize, this.page, this.form.nickname, this.form.phone, this.form.origin || undefined);
      if (result.success) {
        this.page = result.pageNo;
        this.userList = result.data;
        this.totalRows = result.totalRows;
      }
    },
    handleChangePage(val) {
      this.page = val;
      this.loadUserList();
    },
    async handleUserCreditRecord(row) {
      const result = await UserApi.userCreditRecord({
        userId: row.id,
      });
      if (result.success) {
        this.creditRecord = result.data;
      } else {
        this.$message.error('积分详情获取失败');
      }
    },
    async handleUpdateStatus(row, type) {
      let result = undefined;
      if (type === 'ADMIN') {
        result = await UserApi.updateUser({
          ...row,
          isSuperManager: !row.isSuperManager,
          isBackend: true,
        });
      } else {
        result = await UserApi.updateUser({
          ...row,
          auditStatus: type,
          isBackend: true,
        });
      }
      if (result.success) {
        this.$message.success('操作成功!');
        if (type === 'ADMIN') {
          const userInfo = this.$store.getters.getUserInfo;
          this.$store.commit('setUserInfo', {
            ...userInfo,
            isSuperManager: !row.isSuperManager,
          });
        }
        await this.loadUserList();
      } else {
        this.$message.error('操作失败!');
      }
    },
    async handleManagerCreditRecord(row) {
      await CreditRuleApi.sendPoints({
        userId: row.id,
        creditType: 'BK_INCREASE',
      });
      await this.loadUserList();
      this.$message.success('操作成功!');
    },

    async confirmGive() {
      await CreditRuleApi.sendPoints({
        userId: this.userId,
        creditType: 'BK_INCREASE',
      });
      await this.loadUserList();
      this.$message.success('操作成功!');
    },

    async onCancel() {
      this.form.phone = undefined;
      this.form.origin = undefined;
      this.form.nickname = undefined;
      await this.loadUserList();
    },
    async onSearch() {
      await this.loadUserList();
    }
  },
};
</script>
